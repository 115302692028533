<template>
  <div>
    <!-- 发起手机任务 -->
    <!-- <el-row style="margin-bottom: 20px"></el-row> -->
    <el-button type="primary" style="height: 40px; margin: 0 20px 20px 0"
      >导入</el-button
    >
    <!-- 条件 -->
    <el-row style="display: flex; margin-bottom: -20px">
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item label="学年学期" class="formItemBoxStyle">
          <el-cascader
            style="width: 100%"
            v-model="sysSchoolTermIdArr"
            :options="$store.state.public_data.sysAcademicTermYearDicList"
            :props="{
              children: 'children',
            }"
            @change="sysSchoolTermIdChange"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.submitStatus"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.approveStatusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属学段" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.schoolSectionDicId"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.schoolSectionDicIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批状态" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.approveStatus"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.submitStatusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="inquireBtn">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row style="margin-top: 20px">
      <el-table
        v-loading="loading"
        border
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column
          prop="crsExpPlanName"
          label="教学计划名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="申报时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.startTime | dayFilters }}
            <span v-if="scope.row.endTime !== null">~</span>
            {{ scope.row.endTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column prop="schoolSectionDicId" label="所属学段" width="110">
          <template slot-scope="scope">
            {{ sysSemesterTermDicFilters(scope.row.schoolSectionDicId) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sysOrgSchoolGradeName"
          label="所属年级"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="sysSchoolTerm.sysSchoolTermYear"
          label="所属学年"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="sysSchoolTerm.sysSchoolTermName"
          label="所属学期"
          width="180"
        ></el-table-column>
        <el-table-column prop="submitStatus" label="提交状态" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.submitStatus == 1">未提交</span>
            <span v-else-if="scope.row.submitStatus == 2">已提交</span>
            <span v-else-if="scope.row.submitStatus == 3">提交驳回</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column prop="approveStatus" label="审批状态" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.approveStatus == 1">未审批</span>
            <span v-else-if="scope.row.approveStatus == 2">审批通过</span>
            <span v-else-if="scope.row.approveStatus == 3">审批不通过</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column prop="approveMark" label="审批结果" width="120">
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="viewBtn(scope.row)">查看</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="arrangementBtn(scope.row)"
              >排课</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="submitBtn(scope.row)"
              >提交</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getStore } from '@/utils/token.js'
import {
  getCoursePlanCondition,
  getCoursePlan,
  submitCoursePlan,
} from '@/api/courseStart/scheduleLessonsManage.js'
export default {
  name: 'scheduleLessonsManage',
  data() {
    return {
      conditionForm: {
        approveStatus: '',
        schoolSectionDicId: '',
        submitStatus: '',
        sysSchoolTermId: '',
        sysOrgSchoolId: '',
      },
      sysSchoolTermIdArr: [],
      conditionList: {
        // 学段字典id集合
        schoolSectionDicIdList: [],
        // 学校id集合
        sysOrgSchoolIdList: [],
        // 学年学期id集合
        sysSchoolTermIdList: [],
        // 审批状态
        approveStatusList: [
          { name: '未审批', id: 1 },
          { name: '审批通过', id: 2 },
          { name: '审批不通过', id: 3 },
        ],
        // 提交状态
        submitStatusList: [
          { name: '未提交', id: 1 },
          { name: '已提交', id: 2 },
          { name: '驳回提交', id: 3 },
        ],
      },
      //
      loading: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    // 获取学年
    this.conditionList.sysSchoolTermIdList =
      this.$store.state.public_data.sysAcademicYearDicList
    // 获取学段
    this.conditionList.schoolSectionDicIdList =
      this.$store.state.public_data.sysSemesterTermGradeDicList
  },
  mounted() {
    this.conditionForm.sysOrgSchoolId = Number(getStore('sysOrgSchoolId'))
    this.getCoursePlanConditionFunc()
    this.getCoursePlanFunc({
      sysOrgSchoolId: Number(getStore('sysOrgSchoolId')),
    })
  },
  methods: {
    // 查询开课计划过滤条件
    getCoursePlanConditionFunc() {
      getCoursePlanCondition()
        .then((res) => {
          if (res.success) {
            let {
              schoolSectionDicIdList,
              sysOrgSchoolIdList,
              sysSchoolTermIdList,
            } = res.data
            this.conditionList.schoolSectionDicIdList = schoolSectionDicIdList
            this.conditionList.sysOrgSchoolIdList = sysOrgSchoolIdList
            this.conditionList.sysSchoolTermIdList = sysSchoolTermIdList
          }
        })
        .catch((err) => {})
    },
    // 分页查询开课计划
    getCoursePlanFunc(data) {
      getCoursePlan(this.page.pageIndex, this.page.pageSize, data)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data
            this.page.totalSize = res.total
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    sysSchoolTermIdChange(val) {
      if (!!val) {
        this.conditionForm.sysSchoolTermId = this.sysSchoolTermIdArr[1]
      } else {
        this.conditionForm.sysSchoolTermId = ''
      }
      this.changeConditionForm()
    },
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getCoursePlanFunc(conditionObj)
    },
    // 查询
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 重置
    resetBtn() {
      for (let key in this.conditionForm) {
        this.conditionForm[key] = ''
      }
      this.conditionForm.sysOrgSchoolId = Number(getStore('sysOrgSchoolId'))
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 导入
    // 查看转跳
    viewBtn(row) {
      this.$router.push({
        path: '/courseStart/curriculum',
        query: {
          crsOpenPlanId: row.crsOpenPlanId,
        },
      })
    },
    // 排课转跳
    arrangementBtn(row) {
      this.$router.push({
        path: '/courseStart/courseArrangement',
        query: {
          crsOpenPlanId: row.crsOpenPlanId,
          sysOrgSchoolGradeId: row.sysOrgSchoolGradeId,
        },
      })
    },
    // 提交
    submitBtn(row) {
      submitCoursePlan(row.crsOpenPlanId)
        .then((res) => {
          if (res.success) {
            this.$message.success('提交成功')
            this.resetBtn()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    sysSemesterTermDicFilters(key) {
      return this.$store.state.public_data.sysSemesterTermDicList.find(
        (e) => e.value === key
      )?.label
    },
  },
  watch: {},
  filters: {
    tenantStatusFilters(key) {
      switch (key) {
        case 104:
          return `租户未被授权`
        case 102:
          return `租户授权过期`
        case 1:
          return `正常使用中`
        case 0:
          return `租户被禁用`
        default:
          return `状态码${key}`
      }
    },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="sass" scoped></style>
